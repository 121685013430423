import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import './permissions-table-row/permissions-table-row.js';

import { css, html, LitElement } from 'lit';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { novaTableStyles } from '../../../main/components/applications/application-table/styles.js';

class PermissionsTable extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      admins: { type: Array, attribute: false },
      _selectedAdmin: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodySmallStyles,
      novaTableStyles,
      css`

        /* tr.spaceUnder> td {
          padding-bottom: 1em;
        } */

        .nova-table {
          margin-top: 20px;
        }
        .table-row, .table-header {
          grid-template-columns: 150px 300px 200px 400px 75px;
          padding: 5px 20px;
        }

        .table-item {
          align-items: center;
          color: var(--d2l-color-celestine);
          display: flex;
          font-weight: bold;
          margin: 0 10px 0 0;
        }
    `];
  }

  constructor() {
    super();
    this.admins = [];
    this._selectedAdmin = null;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    return html`

      <table class="" >
        <tbody class="nova-table">
        <tr class="table-header d2l-skeletize">
          <th class="table-item name d2l-body-small" >${this.localize('view-admin.permissions.permissions-table.header.name')}</th>
          <th class="table-item email d2l-body-small" > ${this.localize('view-admin.permissions.permissions-table.header.email')}</th>
          <th class="table-item job-title d2l-body-small" >${this.localize('view-admin.permissions.permissions-table.header.jobTitle')}</th>
          <th class="table-item permissions d2l-body-small" >${this.localize('view-admin.permissions.permissions-table.header.permissions')}</th>
          <th class="table-item actions d2l-body-small" >${this.localize('view-admin.permissions.permissions-table.header.actions')}</th>
        </tr>
        ${this.admins.map(admin => html`
          <tr>
            <permissions-table-row .admin=${admin} @nova-activity-state-change="${this._activeStateChanged}"></permissions-table-row>
          </tr>
        `)}
        </tbody>
        </table>
      ${this._confirmationDialogTemplate}
    `;
  }

  get _confirmationDialogTemplate() {
    return html`
      <d2l-dialog-confirm
          id="dialog-confirm"
          text="${this.localize('view-admin.permissions.permissions-table.deleteUserRolesConfirmation.text', { name: this._selectedAdmin?.name })}"
          title-text="${this.localize('view-admin.permissions.permissions-table.deleteUserRolesConfirmation.header')}"
          @d2l-dialog-close=${this._dialogClose()}>
          <d2l-button slot="footer" primary data-dialog-action="yes">${this.localize('view-admin.permissions.permissions-table.deleteUserRolesConfirmation.deleteUserButtonLabel')}</d2l-button>
          <d2l-button slot="footer" data-dialog-action>${this.localize('general.button-text.cancel')}</d2l-button>
        </d2l-dialog-confirm>`;
  }

  async _activeStateChanged(e) {
    this.shadowRoot.querySelector('#dialog-confirm').opened = true;
    const { admin } = e.detail;
    this._selectedAdmin = admin;
    this._confirmationDialogOpened = true;
  }

  _dialogClose() {
    // TODO: remove user from the admin tenant and/or remove all their role assignments
    return async e => {
      this._confirmationDialogOpened = false;
      const { action } = e.detail;
      switch (action) {
        case 'yes':
          this.admins = this.admins.map(admin => (admin.id === this._selectedAdmin.id ? { ...admin, roles: [] } : admin));
          break;
        case 'no':
          break;
      }
      // await this._updateState();
    };
  }

  async _updateState() {
    this.skeleton = true;
    await this.update();
    this.skeleton = false;
  }
}

window.customElements.define('permissions-table', PermissionsTable);
