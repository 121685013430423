import '@brightspace-ui/core/components/button/button.js';
import '../../../../../shared/components/general/app-link/app-link.js';
import '../../../../components/permissions-table/permissions-table.js';

import { css, html, LitElement } from 'lit';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { admins } from './data/mock-users.js';
import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';

class EditPermissions extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      _admins: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      css`
      /* Add styles here */
    `];
  }

  constructor() {
    super();
    this._admins = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this._admins = this._fetchAdmins();
  }

  render() {
    return html`
      <h2>${this.localize('view-admin.permissions.userPermissions')}</h2>
      <p>${this.localize('view-admin.permissions.userPermissions.description')}</p>
      ${this._permissionsTable}
`;
  }

  get _permissionsTable() {
    if (!this._admins.length) {
      return this._emptyStateTemplate;
    }
    return html `<permissions-table .admins=${this._admins}></permissions-table>`;
  }

  get _emptyStateTemplate() {
    return html`
      <div class="empty-state-container">
        <d2l-empty-state-illustrated illustration-name="tumbleweed" description=${this.localize('view-admin.permissions.userPermissions.error.noUsers')} title-text=${this.localize('general.error')}>
          <d2l-empty-state-action-button
            @d2l-empty-state-action=${this._handleEmptyState}
            text=${this.localize('view-admin.permissions.userPermissions.error.action')}
          ></d2l-empty-state-action-button>
        </d2l-empty-state-illustrated>
      </div>
    `;
  }

  _fetchAdmins() {
    return admins;
  }
}

window.customElements.define('edit-permissions', EditPermissions);

