export const admins = [
  {
    'id': '1',
    'name': 'Alice Johnson',
    'jobTitle': 'Software Engineer',
    'email': 'alice.johnson@example.com',
    'roles': ['main guy', 'underling', 'lego builder', 'cat herder'],
  },
  {
    'id': '2',
    'name': 'Robert Smith',
    'jobTitle': 'Project Manager',
    'email': 'robert.smith@example.com',
    'roles': ['underling'],
  },
  {
    'id': '3',
    'name': 'Emily Davis',
    'jobTitle': 'Data Analyst',
    'email': 'emily.davis@example.com',
    'roles': [],
  },
  {
    'id': '4',
    'name': 'Michael Brown',
    'jobTitle': 'Marketing Specialist',
    'email': 'michael.brown@example.com',
    'roles': ['assistant to the regional manager'],
  },
  {
    'id': '5',
    'name': 'Jessica Wilson',
    'jobTitle': 'Graphic Designer',
    'email': 'jessica.wilson@example.com',
    'roles': ['underling', 'assistant to the regional manager'],
  },
  {
    'id': '6',
    'name': 'David Martinez',
    'jobTitle': 'Network Administrator',
    'email': 'david.martinez@example.com',
    'roles': ['main guy'],
  },
  {
    'id': '7',
    'name': 'Linda Clark',
    'jobTitle': 'HR Manager',
    'email': 'linda.clark@example.com',
    'roles': ['main guy', 'underling', 'assistant to the regional manager'],
  },
  {
    'id': '8',
    'name': 'James Lewis',
    'jobTitle': 'Financial Analyst',
    'email': 'james.lewis@example.com',
    'roles': ['underling'],
  },
  {
    'id': '9',
    'name': 'Sarah Lee',
    'jobTitle': 'Content Writer',
    'email': 'sarah.lee@example.com',
    'roles': ['assistant to the regional manager'],
  },
  {
    'id': '10',
    'name': 'Daniel Walker',
    'jobTitle': 'Sales Manager',
    'email': 'daniel.walker@example.com',
    'roles': ['main guy', 'underling'],
  },
  {
    'id': '11',
    'name': 'Karen Hall',
    'jobTitle': 'Customer Support Specialist',
    'email': 'karen.hall@example.com',
    'roles': [],
  },
  {
    'id': '12',
    'name': 'Thomas Allen',
    'jobTitle': 'IT Support Technician',
    'email': 'thomas.allen@example.com',
    'roles': ['underling', 'assistant to the regional manager'],
  },
  {
    'id': '13',
    'name': 'Nancy Young',
    'jobTitle': 'Operations Manager',
    'email': 'nancy.young@example.com',
    'roles': ['main guy'],
  },
  {
    'id': '14',
    'name': 'Christopher Hernandez',
    'jobTitle': 'Software Developer',
    'email': 'christopher.hernandez@example.com',
    'roles': ['main guy', 'assistant to the regional manager'],
  },
  {
    'id': '15',
    'name': 'Patricia King',
    'jobTitle': 'SEO Specialist',
    'email': 'patricia.king@example.com',
    'roles': ['underling'],
  },
  {
    'id': '16',
    'name': 'Matthew Wright',
    'jobTitle': 'UI/UX Designer',
    'email': 'matthew.wright@example.com',
    'roles': ['assistant to the regional manager'],
  },
  {
    'id': '17',
    'name': 'Barbara Scott',
    'jobTitle': 'Product Manager',
    'email': 'barbara.scott@example.com',
    'roles': ['underling'],
  },
  {
    'id': '18',
    'name': 'Joshua Green',
    'jobTitle': 'DevOps Engineer',
    'email': 'joshua.green@example.com',
    'roles': [],
  },
  {
    'id': '19',
    'name': 'Susan Adams',
    'jobTitle': 'Business Analyst',
    'email': 'susan.adams@example.com',
    'roles': ['main guy', 'underling'],
  },
  {
    'id': '20',
    'name': 'Andrew Baker',
    'jobTitle': 'System Administrator',
    'email': 'andrew.baker@example.com',
    'roles': ['assistant to the regional manager'],
  },
  {
    'id': '21',
    'name': 'Margaret Campbell',
    'jobTitle': 'Digital Marketing Manager',
    'email': 'margaret.campbell@example.com',
    'roles': ['main guy'],
  },
  {
    'id': '22',
    'name': 'Steven Evans',
    'jobTitle': 'Data Scientist',
    'email': 'steven.evans@example.com',
    'roles': ['underling', 'assistant to the regional manager'],
  },
  {
    'id': '23',
    'name': 'Donna Rodriguez',
    'jobTitle': 'Office Manager',
    'email': 'donna.rodriguez@example.com',
    'roles': ['main guy', 'underling'],
  },
  {
    'id': '24',
    'name': 'Paul Carter',
    'jobTitle': 'Web Developer',
    'email': 'paul.carter@example.com',
    'roles': [],
  },
  {
    'id': '25',
    'name': 'Jennifer Phillips',
    'jobTitle': 'Graphic Artist',
    'email': 'jennifer.phillips@example.com',
    'roles': ['main guy'],
  },
  {
    'id': '26',
    'name': 'Mark Mitchell',
    'jobTitle': 'Quality Assurance Tester',
    'email': 'mark.mitchell@example.com',
    'roles': ['underling', 'assistant to the regional manager'],
  },
  {
    'id': '27',
    'name': 'Elizabeth Perez',
    'jobTitle': 'Customer Success Manager',
    'email': 'elizabeth.perez@example.com',
    'roles': ['main guy'],
  },
  {
    'id': '28',
    'name': 'Charles Roberts',
    'jobTitle': 'Technical Writer',
    'email': 'charles.roberts@example.com',
    'roles': ['underling'],
  },
  {
    'id': '29',
    'name': 'Jessica Turner',
    'jobTitle': 'Social Media Coordinator',
    'email': 'jessica.turner@example.com',
    'roles': [],
  },
  {
    'id': '30',
    'name': 'Kevin Edwards',
    'jobTitle': 'Research Scientist',
    'email': 'kevin.edwards@example.com',
    'roles': ['main guy', 'assistant to the regional manager'],
  },
];
