import '@brightspace-ui/core/components/button/button-icon';
import '@brightspace-ui/core/components/status-indicator/status-indicator';
import { bodyCompactStyles } from '@brightspace-ui/core/components/typography/styles.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement, nothing } from 'lit';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { novaTableStyles } from '../../../../main/components/applications/application-table/styles.js';

class PermissionsTableRow extends LocalizeNova(SkeletonMixin(LitElement)) {
  static get properties() {
    return {
      admin: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [super.styles,
      bodyCompactStyles,
      novaTableStyles,
      css`
        .table-row, .table-header {
          grid-template-columns: 150px 300px 200px 400px 75px;
          padding: 5px 20px;
        }

        .table-item {
          align-items: center;
          display: flex;
          margin-right: 10px;
          overflow-wrap: break-word;
          word-break: break-word;
        }

        .table-item.roles d2l-status-indicator {
          margin-right: 5px;
        }

        .table-item.action {
          display: flex;
          justify-content: space-around;
        }

        .table-item.action d2l-icon {
          color: var(--d2l-color-tungsten);
        }
    `];
  }

  get _rolesStatusIndicators() {
    if (this.admin?.roles?.length) {
      const sortedRoles = this.admin?.roles.sort((a, b) => a.localeCompare(b));
      const [firstRole, secondRole, ...remainingRoles] = sortedRoles;
      const firstTwoRoles = [firstRole, secondRole].filter(Boolean);
      const remainingRolesString = remainingRoles.join(', ');

      return html`
      ${firstTwoRoles.map(role => html`
        <d2l-status-indicator text="${role}" state="default"></d2l-status-indicator>
      `)}
      ${remainingRoles.length > 0 ? html`
        <d2l-status-indicator text="+${remainingRoles.length} more" state="default" id="remaining-roles"></d2l-status-indicator>
        <d2l-tooltip for="remaining-roles" text="">${remainingRolesString}</d2l-tooltip>
      ` : ''}
    `;
    } else return nothing;

  }

  render() {
    return html`
      <tr class="table-row d2l-skeletize">
        <td class="table-item name d2l-body-compact">${this.admin?.name}</td>
        <td class="table-item email d2l-body-compact">${this.admin?.email}</td>
        <td class="table-item job-title d2l-body-compact">${this.admin?.jobTitle}</td>
        <td class="table-item roles d2l-body-compact">
        ${this._rolesStatusIndicators}
        </td>
        <td class="table-item action">
          <d2l-button-icon text="${this.localize('view-admin.permissions.permissions-table.header.editAdmin')}" icon="tier1:edit" ></d2l-button-icon>
          <d2l-button-icon ?disabled="${!this.admin?.roles.length}" text="${this.localize('view-admin.permissions.permissions-table.header.deleteUser')}" icon="tier1:delete" @click="${this.changeActiveState}"></d2l-button-icon>
        </td>
      </tr>
    `;
  }

  changeActiveState() {
    this.dispatchEvent(new CustomEvent('nova-activity-state-change', {
      detail: { admin: this.admin },
      bubbles: true,
      composed: true,
    }));
  }
}

window.customElements.define('permissions-table-row', PermissionsTableRow);
